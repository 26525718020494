import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
import HTMLContent from "../components/htmlcontent";
import PostTags from "../components/posttags/posttags";
import moment from "moment";
import Comments from "../components/utterances/utterances";

class MusicTemplate extends React.Component {
  render() {
    const md = this.props.data.markdownRemark;
    const { title, attachments, date, tags } = md.frontmatter;
    return (
      <Layout title={title}>
        <h1>{title}</h1>
        <p>{moment(date).format("MMMM DD, YYYY")}</p>
        <HTMLContent html={md.html} />
        <hr />
        {attachments.map((a, i) => (
          <p>
            {a.includes(".mp3") ? (
              <audio controls>
                <source src={a} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <a href={a} key={i}>
                <img className="icon" src="/img/icons/download.svg" alt="download"/>
                {a}
              </a>
            )}
          </p>
        ))}
        <PostTags tags={tags} />
        <Comments/>
      </Layout>
    );
  }
}

export default MusicTemplate;

export const pageQuery = graphql`
  query MusicTemplateQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        attachments
      }
      fields {
        slug
        date
      }
    }
  }
`;
